export function getClientCode () {
  const savedClientCode = localStorage.getItem('CLIENT_CODE');
  if (!savedClientCode) {
    const host = window.location.hostname;

    const [sos, clientCodePart1, clientCodePart2,] = host.split('.');
    let clientCode = `${clientCodePart1}.${clientCodePart2}`;
    console.debug(sos);
    if ((clientCodePart1 === undefined) 
      || (clientCodePart2 === undefined) 
      || host.endsWith('amazonaws.com')
      || host.endsWith('cloudfront.net')
      ) {
      clientCode = 'integrations';
    } 
    localStorage.setItem('CLIENT_CODE', clientCode);
    return clientCode;
  } 
  return savedClientCode;
}


export function getReturnToUrl() {
  const {location: { protocol, host } } = window;
  return `${protocol}//${host}`;
}