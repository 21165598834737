import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors'
import { Route, Switch, Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { useAuth0 } from "../../react-auth0-spa";
import { getClientCode, getReturnToUrl } from '../../utils';

import {
  List, ListItem, ListItemIcon, ListItemText, Paper, Stepper, Step, StepLabel, Box,
  IconButton, AppBar, Toolbar, CssBaseline, Drawer, MenuItem, Menu, Typography,
} from '@material-ui/core';


import MenuIcon from '@material-ui/icons/Menu';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import DevicesIcon from '@material-ui/icons/Devices';
import AccountCircle from '@material-ui/icons/AccountCircle';

import VerificationForm from './VerificationForm';
import ConfigForm from './ConfigForm.component';
import Copyright from './Copyright.component'
import CheckCircle from '@material-ui/icons/CheckCircle';
import Devices from '../scenes/Devices.component';


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    position: 'relative',
  },
  checkCircleColor: {
    fill: green[500]
  },
  layout: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  layoutCenter: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto'
    },
  },
  paper: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  alignCenter: {
    textAlign: 'center'
  },
  Link: {
    color: 'white',
    textDecoration: 'none'
  },
  list: {
    width: 300,
  },
  fullList: {
    width: 'auto',
  },
  drawer: {
    width: 300,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 300,
  },
}));

const steps = ['Verification Code', 'Set Configuration', 'Confirmation'];


export default function Layout(props) {
  useHistory();
  const classes = useStyles();
  const { logout } = useAuth0();
  const [auth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [activeStep, setActiveStep] = React.useState(0);
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const sideList = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer}
      onKeyDown={toggleDrawer}>
      <List>
        <ListItem button key={1} component={Link} to="/" >
          <ListItemIcon><VerifiedUserIcon /></ListItemIcon>
          <ListItemText primary={'Verification'} />
        </ListItem>
        <ListItem button key={2} component={Link} to="/devices">
          <ListItemIcon><DevicesIcon /></ListItemIcon>
          <ListItemText primary={'Devices'} />
        </ListItem>
      </List>
    </div>
  );

  const onMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const cleanLogout = () => {
    localStorage.removeItem('role');
    localStorage.removeItem('token');
    logout({ returnTo: getReturnToUrl() });
  }

  const onClose = () => {
    cleanLogout();
    setAnchorEl(null);
  };

  const onNext = () => {
    setActiveStep(activeStep + 1);
  };

  const onResetActiveStep = () => {
    setActiveStep(0);
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <VerificationForm onNext={onNext} clientCode={getClientCode()} />;
      case 1:
        return <ConfigForm onNext={onNext} clientCode={getClientCode()} />;
      case 2:
        break;
      default:
        throw new Error('Unknown step');
    }
  }


  const role = localStorage.getItem('role');

  const permittedRoles = process.env.REACT_APP_LS_ROLES ? process.env.REACT_APP_LS_ROLES.split(',').map(c => c.trim()) : [];

  if (!permittedRoles.includes(role)) {
    cleanLogout();
    return null;
  }

  return (
    <>
      <CssBaseline />
      <AppBar position="static">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            edge="start"
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Coates SOS Provisioner
          </Typography>

          {auth && (
            <div>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={onMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={onClose}
              >
                <MenuItem onClick={onClose}>Logout</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        open={drawerOpen}
        onClose={toggleDrawer}
        className={classes.drawer}
        classes={{ paper: classes.drawerPaper }}>
        {sideList()}
      </Drawer>
      <main >
        <Switch>
          <Route path="/devices" exact>
            <Devices clientCode={getClientCode()} action={onResetActiveStep} />
          </Route>
          <Route path="/" exact>
            <Paper className={classes.layoutCenter}>
              <Typography component="h1" variant="h4" align="center">
                SOS Provisioning
              </Typography>
              <Typography component="h6" variant="h6" align="center" style={{ marginTop: 20 }}>
                Client Code: {getClientCode()}
              </Typography>
              <Stepper activeStep={activeStep} className={classes.stepper}>
                {steps.map(label => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <>
                {activeStep === steps.length - 1 ? (
                  <Box flexDirection="column" alignItems="center" justifyItems="center" >
                    <div className={classes.alignCenter}>

                      <CheckCircle style={{ fontSize: 80 }} className={classes.checkCircleColor} />
                      <Typography variant="h5" gutterBottom style={{ marginBottom: 50 }} >
                        Provisioning Complete.
                    </Typography>
                      <Typography variant="subtitle1">
                        The provisioning of your device is complete now. You can see this device active in SignageOS Box.
                    </Typography>
                    </div>
                  </Box>
                ) : (
                    <>
                      {getStepContent(activeStep)}
                    </>
                  )}
              </>
            </Paper>
          </Route>
        </Switch>
        <Copyright />
      </main>
    </>
  );
}