import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import { useAuth0 } from "./react-auth0-spa";
import Layout from './components/presentational/Layout.component'

import './App.css';

//create your forceUpdate hook
function useForceUpdate(){
  const [value, setValue] = useState(true); //boolean state
  return () => setValue(!value); // toggle the state to force render
}



function App() {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0();
  
  const theme = createMuiTheme({
    palette: {
      primary: blue,
    },
  });

  const forceUpdate = useForceUpdate();

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <ThemeProvider theme={theme}>
      <Router>

      {!isAuthenticated && loginWithRedirect({})}
      {isAuthenticated && <Layout app={App} forceUpdate={forceUpdate} />}

      </Router>
    </ThemeProvider>
  );
}

export default App;
