import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import { getDevices, deprovisionDevice } from '../../http'
import moment from 'moment';
import MaterialTable from 'material-table';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


const styles = {
  root: {
    padding: 20,
    backgroundColor: '#ffffff',
  }
}


class Devices extends Component {

  state = {
    devices: [],
    dialogOpen: false
  }

  
  onDialogOpen = () => {
    this.setState({ dialogOpen: true, confirmNameError: '' });
  };

  onDialogClose = () => {
    this.setState({ dialogOpen: false, confirmNameError: '' });
  };

  async fetchDevices(clientCode) {
    this.setState({ isProcessing: true });
    const response = await getDevices(clientCode).catch(e => {
      console.error(e.message);
      this.setState({ isProcessing: false });
    });

    if (response) {
      this.setState({ devices: response.data.data, isProcessing: false });
    }
  }

  async componentDidMount(props) {
    this.clientCode = localStorage.getItem('CLIENT_CODE') || 'ca.mcd';
    this.props.action();
    await this.fetchDevices(this.clientCode);
  }

  onDeviceNameChange = (e) => {
    this.setState({ confirmName: e.target.value });
  }

  onDeprovisionClick(uid, name) {
    this.setState({ dialogOpen: true, uid, name });
  }

  deprovision = async () => {
    if (this.state.name !== this.state.confirmName && this.state.uid !== this.state.confirmName) {
      this.setState({ confirmNameError: 'Sorry, no device with this name or UID!' })
      return;
    }

    this.setState({ isDeleting: true });
    const response = await deprovisionDevice(this.state.uid, this.clientCode).catch(e => {
      console.error(e.message);
      this.setState({ isDeleting: false });
    });

    await this.fetchDevices(this.clientCode);

    if (response) {
      this.setState({ isDeleting: false, dialogOpen: false });
    }
  }

  render() {
    const { devices, isProcessing } = this.state;

    if (!devices) {
      return null;
    }

    const columns = [
      { title: 'Name', field: 'name' },
      { title: 'UID', field: 'uid' },
      { title: 'Last Seen', field: 'aliveAt' },
      { title: 'IP Address', field: 'ipAddress' },
      { title: 'Free Space', field: 'freeSpace' },
    ];

    const data = devices.map(device => ({
      name: device.name,
      uid: device.uid,
      aliveAt: moment(device.aliveAt).format('DD/MM/YYYY hh:mma'),
      ipAddress: device.networkInterfaces && device.networkInterfaces.ethernet ? device.networkInterfaces.ethernet.ipAddress : '',
      freeSpace: device.storageStatus && device.storageStatus.internal ? (device.storageStatus.internal.freeSpace / (1024 * 1024 * 1024)).toFixed(2) + 'GB' : '',
    }));

    return (
      <div style={styles.root}>
        <MaterialTable 
          title={`Devices of ${this.clientCode}`}
          columns={columns} 
          data={data}
          options={{ pageSize:10 }}
          isLoading={isProcessing}
          actions={[
            {
              icon: 'delete',
              tooltip: 'Deprovision',
              onClick: (event, rowData) => {
                this.onDeprovisionClick(rowData.uid, rowData.name);
              }
            }
          ]}
        />
        <Dialog open={this.state.dialogOpen} onClose={this.onDialogClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Deprovision Device</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To deprovision the device, please enter the {
                this.state.name ? `name of device ${this.state.name}` : `UID of device ${this.state.uid}`
              }  in the textbox below and press Deprovision.
            </DialogContentText>
            <TextField
              autoFocus
              onChange={this.onDeviceNameChange}
              margin="dense"
              id="name"
              label="Device Name or UID"
              type="text"
              fullWidth
              disabled={this.state.isDeleting}
              error={Boolean(this.state.confirmNameError)}
              helperText={this.state.confirmNameError || ''}
            />
            {this.state.isDeleting && <CircularProgress />}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onDialogClose} color="primary" disabled={this.state.isDeleting} >
              Cancel
          </Button>
            <Button onClick={this.deprovision} color="secondary" disabled={this.state.isDeleting}>
              Deprovision
          </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default withStyles(styles)(Devices);