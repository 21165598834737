import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { updateDetails } from '../../http'
export default class ConfigForm extends Component {

  state = {
    errors: {},
    superMasterIp: '',
    locationCode: '',
    clientCode: '',
  }
  onChange = (e) => {
    const state = { ...this.state };
    state[e.target.name] = e.target.value;
    if (state.errors[e.target.name]) {
      delete state.errors[e.target.name];
    }
    this.setState(state);
  }

  getError(fieldName) {
    const { errors } = this.state;
    return errors[fieldName] ? true : false;
  }

  getHelperText(fieldName) {
    const { errors } = this.state;
    return errors[fieldName] || '';
  }


  onSubmit = (e) => {
    e.preventDefault();
    const { superMasterIp, locationCode } = this.state;

    const { clientCode } = this.props;

    const errors = {};
    const regex = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
    if (!regex.test(superMasterIp)) {
      errors.superMasterIp = 'Not a valid IP v4';
    }

    if (errors.superMasterIp) {
      this.setState({ errors });
    } else {
      this.setState({ isProcessing: true });
      const deviceUid = localStorage.getItem('deviceUid');
      updateDetails({ clientCode, locationCode, deviceUid, superMasterIp })
        .then(res => {
          this.setState({ isProcessing: false, error: null });
          this.props.onNext();
        }).catch(e => {
          const { error } = e.response.data;
          let err = this.state.errors;
          if (error.includes('Location')) {
            console.log('d: ', error.includes('Location'));
            err.locationCode = error.split(':')[2];
          }
          this.setState({ isProcessing: false, error, errors: err });
        });
    }
  }

  
  render() {

    return (
      <form onSubmit={this.onSubmit}>
        <Typography variant="h6" gutterBottom>
          Set configuration
        </Typography>
        <div style={{marginTop: 10}}>Device UID: {localStorage.getItem('deviceUid')}</div>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <TextField required id="superMasterIp" fullWidth
              name="superMasterIp"
              label='Super Master IP'
              error={this.getError('superMasterIp')}
              helperText={this.getHelperText('superMasterIp')}
              onChange={this.onChange}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              required
              id="locationCode"
              name="locationCode"
              label="Location Code"
              onChange={this.onChange}
              error={this.getError('locationCode')}
              helperText={this.getHelperText('locationCode')}
              fullWidth
            />
          </Grid>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ marginTop: 20 }}
            disabled={this.state.isProcessing}
          >
            Set Configuration
          </Button>
        </Grid>
      </form>
    );
  }
}