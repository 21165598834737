import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { verifyHash } from '../../http';

export default class VerificationForm extends Component {
  VERIFICATION_CODE_LENGTH = 6;
  state = {
    verificationCode: '',
    isProcessing: false,
    clientCode: this.props.clientCode
  }

  onChange = (e) => {
    const {state} = this;
    state.errors = state.errors || {};
    state[e.target.name] = e.target.value;
    state.errors[e.target.name] = null;
    this.setState(state);
  }



  onSubmit = (e) => {
    e.preventDefault();
    const { state } = this;
    const { verificationCode, clientCode } = this.state
    if (verificationCode.length !== this.VERIFICATION_CODE_LENGTH) {
      return this.setState({ error: { field: 'verificationCode', message: `Verification Code should be ${this.VERIFICATION_CODE_LENGTH} chars long` }});
    }

    if (state.clientCode) {
      localStorage.setItem('CLIENT_CODE', clientCode);
    }

    this.setState({ isProcessing: true });
    verifyHash(verificationCode, clientCode)
      .then(res => {
        const { data: { deviceUid } } = res.data;
        localStorage.setItem('verificationCode', verificationCode);
        localStorage.setItem('deviceUid', deviceUid);
        this.setState({ deviceUid, isProcessing: false, error: null });
        this.props.onNext();
      }).catch(e => {
        let { error: message } = e.response.data;
        const field = message.startsWith('ERR_VERIFICATION_CODE') ? 'verificationCode' : message.startsWith('ERR_CLIENT_CODE') ? 'clientCode': '';
        const [ errCode, errMsg ] = message.split(': ');
        console.log({ errCode, errMsg });
        if (errMsg) {
          message = errMsg;
        }
        this.setState({ isProcessing: false, error: { message, field } });
      });
  }

  render() {
    const { error, isProcessing } = this.state

    return (
      
        <form onSubmit={this.onSubmit} >
          <Typography variant="h6" gutterBottom>
            Verification Code
        </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <TextField
                required
                id="verificationCode"
                name="verificationCode"
                fullWidth
                label="Verification Code"
                error={error && error.field === 'verificationCode'}
                helperText={error && error.field === 'verificationCode' && error.message}
                onChange={this.onChange}
              />
              
              <TextField
                required
                id="clientCode"
                name="clientCode"
                fullWidth
                label="Client Code"
                error={error && error.field === 'clientCode'}
                helperText={error && error.field === 'clientCode' && error.message}
                onChange={this.onChange}
                defaultValue={this.state.clientCode}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isProcessing}
                style={{ marginTop: 20 }}
              >
                {this.state.isProcessing ? 'Verifying...' : 'Verify Now'}
              </Button>
            </Grid>
          </Grid>
        </form>
      
    );
  }
}