import axios from 'axios';
const url = process.env.REACT_APP_API_HOST

const setAuthHeader = () => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

export async function verifyHash(verificationHash, clientCode) {
  const params = clientCode ? { verificationHash, clientCode } : { verificationHash };
  setAuthHeader();
  return await axios.post(`${url}/devices`, params);
}

export async function updateDetails({
  deviceUid, superMasterIp, clientCode, locationCode
}) {
  setAuthHeader();
  return await axios.patch(`${url}/devices/${deviceUid}`, { superMasterIp, clientCode, locationCode });
}

export async function getDevices(clientCode) {
  const apiUrl = clientCode ? `${url}/devices?clientcode=${clientCode}` : `${url}/devices`
  setAuthHeader();
  return await axios.get(apiUrl);
}

export async function deprovisionDevice(deviceUid, clientCode) {
  const apiUrl = clientCode ? `${url}/devices/${deviceUid}?clientcode=${clientCode}` : `${url}/devices/${deviceUid}`
  setAuthHeader();
  return await axios.delete(apiUrl);
}